import { Print } from '.';
import Model from './Model';

export default class PrintPreview extends Model {
  static type = 'printPreviews';
  ordersCount!: number;
  filesCount: number;
  imagesCount: number;
  productsCount: number;
  outputsCount: number;
  filesToComplete: number;


  static empty() {
    const preview = new PrintPreview();

    preview.ordersCount = 0;
    preview.filesCount = 0;
    preview.imagesCount = 0;
    preview.productsCount = 0;
    preview.outputsCount = 0;
    preview.filesToComplete = 0;
    return preview;
  }

  static fromPrint(print: Print) {
    const preview = new PrintPreview();
    preview.outputsCount = print.outputsCount;
    preview.filesCount = print.totalPieces;
    const category = print.printCategory;
    preview.filesToComplete = category.size - (preview.filesCount % category.size);
    return preview;
  }

}
