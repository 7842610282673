const routes = [
  { path: '/coupons/show/:id', component: () => import(/* webpackChunkName: "source" */ '@/views/Coupon/Show.vue'), name: 'coupons_show'}
,
  {
    path: '/coupons', component: () => import(/* webpackChunkName: "source" */ '@/views/Coupon/Dashboard.vue'), name: 'coupons', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      { path: '/coupons/list', component: () => import(/* webpackChunkName: "source" */ '@/views/Coupon/List.vue'), name: 'coupons_list'},
    ]
  }
];

export default routes;
