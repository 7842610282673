const routes = [
  {
    path: 'sections',
    component: () => import('@/views/StoreFront/Panel/Content/Section/Index.vue'),
    name: 'store_front_sections',
    meta: { role: ['superadmin'] },
    children: [
      {
        path: 'list',
        component: () => import('@/views/StoreFront/Panel/Content/Section/List.vue'),
        name: 'store_front_sections_list',
        meta: { role: ['superadmin'] },
      },
      {
        path: ':sectionId',
        component: () => import('@/views/StoreFront/Panel/Content/Section/Show.vue'),
        name: 'store_front_sections_show',
        meta: { role: ['superadmin'] },
      }
    ]
  }
];

export default routes;
