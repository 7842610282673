









import { Vue, Component, Prop } from "vue-property-decorator";
import { BASE_URL } from '@/utils/Constants';

@Component({})
export default class Credits extends Vue {

  get logo() {
    return `${BASE_URL}/assets/logo.png`
  }
}
