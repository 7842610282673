const routes = [
  {
    path: "/store_fronts/show/:id",
    component: () => import("@/views/StoreFront/Show.vue"),
    name: "store_fronts_show",
    meta: { role: ["superadmin", "admin", "manager"] }
  },
  {
    path: "/store_fronts",
    component: () => import("@/views/StoreFront/Dashboard.vue"),
    name: "store_fronts",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },
      {
        path: "/store_fronts/list",
        component: () => import("@/views/StoreFront/List.vue"),
        name: "store_fronts_list"
      },

    ]
  }
];

export default routes;
