const routes = [
  {
    path: "/warehouse_items/show/:id",
    component: () => import("@/views/WarehouseItem/Show.vue"),
    name: "warehouse_items_show",
    meta: { role: ["superadmin", "admin", "manager"] }
  },
  {
    path: "/warehouse_items",
    component: () => import("@/views/WarehouseItem/Dashboard.vue"),
    name: "warehouse_items",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },
      {
        path: "/warehouse_items/list",
        component: () => import("@/views/WarehouseItem/List.vue"),
        name: "warehouse_items_list"
      },
      {
        path: "/warehouse_items/diffs",
        component: () => import("@/views/WarehouseItem/Diffs.vue"),
        name: "warehouse_items_diff"
      }
    ]
  }
];

export default routes;
