import Vue from 'vue';
import Vuex from 'vuex';
import http from './http';
import storeFront from './storeFront';
import auth from './auth';
import currentUser from './currentUser';
import { cable } from './cable';
import VuexPersist from 'vuex-persist';
import source from './source';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'rexqo-logistics',
  storage: window.localStorage,
  modules: ['auth', 'source', 'storeFront']
});

export default new Vuex.Store({
  modules: {
    auth, currentUser, http, source, cable, storeFront
  },
  plugins: [vuexPersist.plugin]
});
