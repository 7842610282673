const routes = [
  {
    path: 'banners',
    component: () => import('@/views/StoreFront/Panel/Content/Banner/Index.vue'),
    name: 'banners',
    meta: { role: ['superadmin'] },
    children: [
      {
        path: ':bannerId',
        component: () => import('@/views/StoreFront/Panel/Content/Banner/Update.vue'),
        name: 'banners_update',
        meta: { role: ['superadmin'] },
      }
    ]
  },
  {
    path: 'banners_create',
    component: () => import('@/views/StoreFront/Panel/Content/Banner/Create.vue'),
    name: 'banners_create',
    meta: { role: ['superadmin'] },
  }
];

export default routes;
