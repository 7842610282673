import { PrintCategory } from ".";
import Model from "./Model";

export default class Print extends Model {
  static type = "prints";

  static statuses: Array<{ name: string; id: string; style?: string }> = [
    { name: "Inattiva", id: "idle", style: "secondary" },
    { name: "In stampa", id: "processing", style: "warning" },
    { name: "Errore", id: "error", style: "danger" },
    { name: "Completata", id: "completed", style: "success" }
  ];

  createdAt: Date;
  status: string;
  name: string;
  printCategory: PrintCategory;
  outputs: { url: string; metadata: any }[];
  outputsCount: number;
  totalPieces: number;
  filesCount: number;
  printGroupId: number;

  get formattedStatus() {
    return Print.statuses.find(o => o.id === this.status)?.name;
  }

  get statusStyle() {
    return Print.statuses.find(o => o.id === this.status)?.style;
  }

  get completed() {
    return this.status == "completed";
  }
}
