import Model from "./Model";
import { WarehouseItem } from ".";
export default class ProductWarehouseItem extends Model{
  static type = "productWarehouseItems";
  productId: string;
  warehouseItem: WarehouseItem;
  quantity: number
}

export class ProductWarehouseItemPayload{

    warehouseItemId: string;
    productId: string;
    quantity: number;
    id: string;

    static empty() {
      const instance  = new ProductWarehouseItemPayload();
      instance.quantity =1;
      instance.productId = null;
      instance.warehouseItemId = null;
      instance.id = null;
      return instance;
    }

    static fromModel(model: ProductWarehouseItem) {
      const instance  = new ProductWarehouseItemPayload();
      instance.quantity =model.quantity||0;
      instance.productId = model.productId;
      instance.id = model.id;

      instance.warehouseItemId = model.warehouseItem.id;
      return instance;

    }

}
