import Model from './Model';
import IManufacture from './interfaces/IManufacture';
import { ShippingContract } from '.';

export class Match {
  key: string;
  scope: 'tracking' | 'shipment';
  shipmentStatus: string;


  static empty() {

    const match = new Match();

    match.key = null; match.scope = null; match.shipmentStatus = null
    return match;
  }

}

export default class CarrierStatus extends Model {
  static type = 'carrierStatuses';

  static scopes: Array<{ name: string; id: string }> = [
    { name: "Spedizione", id: "shipment" },
    { name: "Tracking", id: "tracking" }
  ];

  name: string;
  carrier: string;
  matches: Match[];

  get carrierFormatted() {
    return ShippingContract.carriers.find(o => o.code === this.carrier)?.name || '-';
  }


  static empty() {

    const item = new CarrierStatus(undefined);

    item.name = null;
    item.carrier = null;
    item.matches = [];
    return item;
  }
}
