import DiscountRoutes from './Discount';
import ContentRoutes from './Content';
import PreviewRoutes from './Preview';

const routes = [
  {
    path: "/store_fronts/panel/:id",
    component: () => import("@/views/StoreFront/Panel/Panel.vue"),
    name: "store_fronts_panel",
    redirect: 'discounts',
    children: [
      ...DiscountRoutes,
      ...ContentRoutes,
      ...PreviewRoutes
    ],
    meta: { role: ["superadmin", "admin", "manager"] }
  },
];

export default routes;
