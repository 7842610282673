import Model from "./Model";

export class BannerPayload {
  id?: string = null;
  name?: string = null;
  title?: string;
  description?: string = null;
  status?: string = null;
  bannerGroupId?: string = null;
  actionUrl?: string = null;
  actionButton?: string = null;
  imageUrl?: string = null;
  image?: any = null;

  static fromData(data: Banner) {
    const item = new BannerPayload();
    item.id = data.id;
    item.name  = data.name || null;
    item.title  = data.title || null
    item.description  = data.description || null;
    item.status  = data.status || null;
    item.bannerGroupId  = data.bannerGroupId || null;
    item.actionUrl  = data.actionUrl || null;
    item.actionButton  = data.actionButton || null;
    item.imageUrl = data.imageUrl || null;
    
    return item;
  }

  static toFormData(payload: BannerPayload): FormData {
    const data = new FormData();

    data.append('name', payload.name);
    data.append('title', payload.title);
    data.append('description', payload.description);
    data.append('status', payload.status);
    data.append('bannerGroupId', payload.bannerGroupId);
    data.append('actionUrl', payload.actionUrl);
    data.append('actionButton', payload.actionButton);
    if (payload.image) {
      data.append('image', payload.image.file);
    }

    return data;
  }
}

export default class Banner extends Model {
  static type = "banners";

  name?: string;
  title?: string;
  description?: string;
  status?: string;
  bannerGroupId?: string;
  actionUrl?: string;
  actionButton?: string;
  imageUrl?: string;
  clickCount?: number;

  get isPublished() {
    return this.status === 'published';
  }
}

export const BannerStatuses: { id: string; name: string; color: string; colorName?: string }[] = [
  { id: 'draft', name: 'Draft', color: '#9fabbc', colorName: 'secondary' },
  { id: 'published', name: 'Pubblicato', color: '#00ef88', colorName: 'success' },
];
