const routes = [
  {
    path: 'category_groups',
    component: () => import('@/views/StoreFront/Panel/Content/CategoryGroup/Index.vue'),
    name: 'category_groups',
    meta: { role: ['superadmin'] },
    children: [
      {
        path: 'list',
        component: () => import('@/views/StoreFront/Panel/Content/CategoryGroup/List.vue'),
        name: 'category_groups_list',
        meta: { role: ['superadmin'] },
      },
      {
        path: ':categoryGroupId',
        component: () => import('@/views/StoreFront/Panel/Content/CategoryGroup/Show.vue'),
        name: 'category_groups_show',
        meta: { role: ['superadmin'] },
      }
    ]
  }
];

export default routes;
