import Model from './Model';
import IPaymentMethod from './interfaces/IPaymentMethod';

export default class PaymentMethod extends Model implements IPaymentMethod {
  static type = 'paymentMethods';
  name!: string;
  code!: string;

  get isPaypal() {
    return this.name.toLowerCase() === 'paypal';
  }
}
