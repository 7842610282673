import { Shipment } from ".";
import Model from "./Model";

export default class Tracking extends Model {
  static type = "trackings";

  shipment: Shipment;
  where: string;
  message: string;
  reason: string;
  when: Date;
}
