import Model from "./Model";

export class LabelPayload {
  id?: string = null;
  name?: string = null;
  url?: string = null;
  cutUrl?: string = null;
  companyId: string = null;

  static fromData(data: Label) {
    const item = new LabelPayload();
    item.name = data.name;
    item.id = data.id;
    item.url = data.url;
    item.cutUrl = data.cutUrl;
    item.companyId = data.companyId;
  }
}

export default class Label extends Model {
  static type = "labels";

  name: string;
  url: string;
  companyId: string;
  cutUrl: string;
}
