import OrderRoutes from "./Order";
import ProductRoutes from "./Product";
import CustomerRoutes from "./Customer";
import CouponRoutes from "./Coupon";

const routes = [
  {
    path: "/source",
    component: () => import(/* webpackChunkName: "source" */ "@/views/Source/Dashboard.vue"),
    name: "source",
    meta: { role: ["anyone"] },
    children: [
      { path: "", redirect: "webhooks" },
      {
        path: "webhooks",
        component: () => import(/* webpackChunkName: "source" */ "@/views/Webhook/List.vue"),
        name: "source_webhooks"
      },
      {
        path: "statuses",
        component: () => import(/* webpackChunkName: "source" */ "@/views/OrderStatus/List.vue"),
        name: "source_statuses"
      },
      {
        path: "shipping_methods",
        component: () => import(/* webpackChunkName: "source" */ "@/views/ShippingMethod/List.vue"),
        name: "source_shipping_methods"
      }
    ]
  },
  {
    path: "/source_detail/:id",
    component: () => import(/* webpackChunkName: "source" */ "@/views/Source/Show.vue"),
    name: "source_detail",
    meta: { role: ["anyone"] }
  },
  {
    path: "/source_create",
    component: () => import(/* webpackChunkName: "source" */ "@/views/Source/Create.vue"),
    name: "source_create",
    meta: { role: ["anyone"] }
  },
  {
    path: "/sources",
    component: () => import(/* webpackChunkName: "source" */ "@/views/Source/List.vue"),
    name: "sources",
    meta: { role: ["anyone"] }
  },
  ...OrderRoutes,
  ...ProductRoutes,
  ...CustomerRoutes,
  ...CouponRoutes
];

export default routes;
