import { Source } from ".";
import Model from "./Model";

export const STATUS_CLASSES: Record<string, string> = {
  'to_approve': 'badge badge-warning',
  'rejected': 'badge badge-danger',
  'accepted': 'badge badge-success',
}

export const STATUS_NAMES: Record<string, string> = {
  'to_approve': 'Da approvare',
  'rejected': 'Fallito',
  'accepted': 'Accettato',
}

export const CLASS_NAME_TO_ROUTE: Record<string, { routeName: string; italianModelName: string }> = {
  'Woocommerce::Product': { routeName: 'products_show', italianModelName: 'Prodotto' },
  'Product': { routeName: 'products_show', italianModelName: 'Prodotto' },
  'WarehouseItem': { routeName: 'warehouse_items_show', italianModelName: 'Prodotto di magazzino' }
}

export class ImportPayload {
  id?: string = null;
  name: string = null;
  importerType: string = null;
  status: string = null;
  sourceId: string = null;
  file: any = null;

  static fromData(data: Import) {
    const item = new ImportPayload();
    item.id = data.id;
    item.name = data.name;
    item.importerType = data.importerType;
    item.status = data.status;
    item.sourceId = data.sourceId || '';
    item.file = data.filename;
    return item;
  }

  static toFormData(data: ImportPayload) {
    const formData = new FormData();

    if (data.id) {
      formData.append('id', data.id);
    }
    if (data.status) {
      formData.append('status', data.status.toString());
    }
    if (data.file?.file) {
      formData.append('file', data.file.file);
    }
    if (data.name) {
      formData.append('name', data.name);
    }
    if (data.importerType) {
      formData.append('importerType', data.importerType);
    }
    if (data.sourceId) {
      formData.append('sourceId', data.sourceId);
    }

    return formData;
  }
}

export default class Import extends Model {
  static type = "imports";

  name: string;
  importerType: string;
  status: string;
  source: Source;
  url: string;
  sourceId: string;
  filename: string;
  validationErrors: any[];
  importedResources: any[];

  get isAccepted() {
    return this.status === 'accepted';
  }

  get isToApprove() {
    return this.status === 'to_approve';
  }

  get entitiesImporter() {
    return this.importerType === 'Importers::EntitiesImporter';
  }

  get zakekeImporter() {
    return this.importerType === 'Importers::ZakekeImporter';
  }

  get orderImporter() {
    return this.importerType === 'Importers::OrderImporter';
  }

  get productConfigurationImporter() {
    return this.importerType === 'Importers::ProductConfigurationImporter';
  }

  get b2bOrdersImporter() {
    return this.importerType === 'Importers::B2bOrdersImporter';
  }
}
