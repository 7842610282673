










import Topbar from "@/components/TheTopbar.vue";
import Sidebar from "@/components/TheSidebar.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import StoreSubscriber from "@/store/StoreSubscriber";
import { Source } from "@/models";
import { namespace } from "vuex-class";
import { sourceStore } from "@/store/typed";

@Component({ components: { Topbar, Sidebar } })
export default class Container extends Vue {
  mounted() {
    this.subscribe();
  }
  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (sourceStore.currentSource !== source) {
      if(!this.$route.name.startsWith('store_front')){
        this.$router.push({ name: "source_webhooks" });
      }
    }
  }
}
