import { PrintTechnology } from '.';
import Model from './Model';
import PrintSide from './PrintSide';

export class PrintCategoryPayload {
  id: string = null;
  name: string = null;
  color: string = null;
  rows: number = null;
  columns: number = null;
  width: number = null;
  height: number = null;
  imageWidth: number = null;
  imageHeight: number = null;
  hide: boolean = null;
  printQrCode: boolean = null;
  customerType: string = null;
  printTechnologyId: string = null;
  sourceId?: string = null;
  qrCodeCutUrl: string = null
  printSides: PrintSide[] = [];
}


export default class PrintCategory extends Model {
  static type = 'printCategories';

  name: string;
  color: string;
  rows: number;
  columns: number;
  width: number;
  height: number;
  imageWidth: number;
  imageHeight: number;
  hide: boolean;
  printQrCode: boolean;
  customerType: string;
  printTechnology: PrintTechnology;
  qrCodeCutUrl: string;
  printSides: PrintSide[];



  get size() {
    return this.rows * this.columns;
  }}
