















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class EmptyView extends Vue {
  @Prop() readonly primaryText!: string;
  @Prop() readonly secondaryText!: string;
  @Prop() readonly action?: string;
  @Prop({ default: "btn btn-primary" }) readonly actionClass?: string;
}
