






import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "icon" })
export default class VCircleIcon extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ default: "md" }) size!: string;
  @Prop({ default: "" }) iconClass?: string;
}
