import Model from "./Model";
import ShippingMethod from "./ShippingMethod";

export class ShippingContractPayload {
  id?: string = null;
  type?: string = null;
  shippingMethodId?: string = null;
  customerCode?: string = null;
  password?: string = null;

  static fromData(data: ShippingContract) {
    const item = new ShippingContractPayload();
    item.id = data.id;
    item.type = data.type;
    item.shippingMethodId = data.shippingMethod?.id;
    item.customerCode = data.customerCode;
    item.password = data.password;
    return item;
  }
}

export default class ShippingContract extends Model {
  static type = "shippingContracts";

  static carriers: Array<{ name: string; id: string; code: string }> = [
    { name: "GLS", id: "ShippingContract::Gls", code: "gls" }
  ];

  type: string;
  shippingMethod: ShippingMethod;
  customerCode: string;
  password: string;
}
