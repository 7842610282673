











import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { User } from "@/models";
import EntrySearch from "@/components/VEntrySearch.vue";
import TopbarDropdown from "@/components/TopbarDropdown.vue";

@Component({ components: { EntrySearch, TopbarDropdown } })
export default class TheTopbar extends Vue {
  @Getter currentUser!: User;
}
