import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";
export const LIST_TAG = "get_sources";

const BASE_SOURCE_URL = BASE_API_URL+"sources"


@Component
export default class SourcesMixin extends Vue {
  getSources(data: any, tag: string) {
    const url = BASE_API_URL+"sources"
    return this.$httpGetQuery(
      url,
      tag,
      data
    );
  }

  getSource(id: number, tag: string) {
    const url = `${BASE_API_URL}sources/${id}`
    return this.$httpGet(
      url,
      tag
    );
  }

  syncSource(id: number, tag: string) {
    const url = `${BASE_API_URL}sources/${id}/sync`

    return this.$httpPost(
      url,
      tag,
      {}
    );
  }

  syncSourceOrderStatuses(id: number, tag: string) {
    const url = `${BASE_API_URL}sources/${id}/sync_order_statuses`
    return this.$httpPost(
      url,
      tag,
      {}
    );
  }

  syncSourceShippingMethods(id: number, tag: string) {
    const url = `${BASE_API_URL}sources/${id}/sync_shipping_methods`

    return this.$httpPost(
      url,
      tag,
      {}
    );
  }

  destroySource(id: string|number, tag: string) {
    return this.$httpDelete(BASE_SOURCE_URL+"/"+id, tag);
  }

  getSourceStats(id: number, tag: string) {
    return this.$httpGet(
      BASE_SOURCE_URL+"/"+id+"/stats",
      tag
    );
  }

  createSource(data: any, tag: string) {
    return this.$httpPost( BASE_SOURCE_URL, tag, data);
  }

  updateSource(data: any, tag: string) {
    return this.$httpPut(
      BASE_SOURCE_URL+"/"+data.id,
      tag,
      data
    );
  }

  updateSourceFormData(id: string,data: any, tag: string) {
    return this.$httpPut(
      BASE_SOURCE_URL+"/"+id,
      tag,
      data
    );
  }
}
