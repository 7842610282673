import Model from './Model';
import { Manufacture, WarehouseItem } from '.';
import PrintTechnology from './PrintTechnology';

export class CreateWarehouseItemManufacture {
  warehouseItemId: string = null;
  manufactureId: string = null;
  printTechnologyId: string = null;
}

export default class WarehouseItemManufacture extends Model {
  static type = 'warehouseItemManufactures';

  warehouseItem: WarehouseItem;
  printTechnology: PrintTechnology;
  manufacture: Manufacture;
}
