const routes = [
  { path: '/customers/show/:id', component: () => import(/* webpackChunkName: "source" */ '@/views/Customer/Show.vue'), name: 'customers_show'}
,
  {
    path: '/customers', component: () => import(/* webpackChunkName: "source" */ '@/views/Customer/Dashboard.vue'), name: 'customers', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      { path: '/customers/list', component: () => import(/* webpackChunkName: "source" */ '@/views/Customer/List.vue'), name: 'customers_list'},
    ]
  }
];

export default routes;
