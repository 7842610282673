
import  {Module, MutationTree, GetterTree } from 'vuex';
import RootState from '@/store/types/RootState';
import { ActionTree } from 'vuex';
import CableStore from '@/store/types/CableState';
import {Consumer, Subscription,createConsumer } from '@rails/actioncable';
import {BASE_API_URL} from '@/utils/Constants';

export const state: CableStore = {
  consumer: undefined,
};

export const getters: GetterTree<CableStore, RootState> = {
  consumer(state): Consumer | undefined {
    return state.consumer;
  }
};

export const mutations: MutationTree<CableStore> = {

    init(state,token: string) {
      if (state.consumer) {
        return;
      }
      state.consumer = createConsumer(
        `${BASE_API_URL}ws?token=${token}`
      );
    },

    disconnect(state) {
      state.consumer?.disconnect();
      state.consumer = undefined;
    }
};

export const actions: ActionTree<CableStore, RootState> = {
  subscribe<T>({ commit, rootGetters, getters }: any, data: {params: any, handler: T}): Promise<T & Subscription> {
    const token = rootGetters['auth/accessToken'];
    commit('init', token);
    const subscription = getters.consumer.subscriptions.create(data.params, data.handler);
    return Promise.resolve(subscription);
  }
};

export const cable: Module<CableStore, RootState> = {
  state,
  getters,
  mutations,
  actions
};
