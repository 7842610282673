import axios from 'axios';
import Vue from 'vue';
import Router from '@/router/index';

// const token = document.head.querySelector('meta[name="csrf-token"]');

// if (token) {
//     axios.defaults.headers.common['X-CSRF-TOKEN'] = token.getAttribute('content');
// } else {
//     console.error('CSRF token not found');
// }


axios.interceptors.request.use(
   (request: any) =>{
    if(request.url.endsWith('.svg')){
      delete request.headers.common['Authorization'];
    }
    return request;

  },
  (error)=>
    error
);

axios.interceptors.response.use(
  (r) => r, (error) => {

    if (error?.response?.status === 422 || error?.response.status === 404) {
      return Promise.reject(error);
    }

    const message = error.response?.data && (error.response.data.errors && error.response.data.errors.message || error.response.data.message) || error.message;
    Vue.toasted.show(message, {
      position: 'bottom-right', type: 'error', icon: 'warning', duration: 5000, keepOnHover: true,
      action: {
        text: 'Chiudi',
        onClick(e: any, toastObject: any) {
          toastObject.goAway(0);
        }
      }
    } as any);
    if (error.response.status === 401) {
      if ( Router.currentRoute.name !== 'login') {
        Router.push('/login');
      }
    }
    return Promise.reject(error);
  }
);
