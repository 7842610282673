import { Manufacture } from ".";
import InvoiceLine from "./InvoiceLine";
import Model from "./Model";

export default class InvoiceLineManufacture extends Model {
  static type = "invoiceLineManufactures";

  manufacture: Manufacture;
  invoiceLine: InvoiceLine;
}
