import Model from "./Model";
import Media from "./Media";
import { WarehouseItem } from ".";
import PrintLine from "./PrintLine";
import ProductLabel from "./ProductLabel";
export default class Product extends Model{
  static type = "products";

  static selectableColumns = [
    { id: "sku", label: "Sku", type: "text" },
    { id: "name", label: "Nome", type: "text" },
    { id: "preview", label: "immagine", type: "image" },
    { id: "price", label: "Prezzo", type: "text" },
    { id: "external_id", label: "Id Ecommerce", type: "text" }
  ];

  externalId: string;
  name: string;
  approved: boolean;
  description: string;
  sku: number;
  warehouseItems: WarehouseItem[];
  price: number;
  printCategory: any;
  creditsX: number;
  creditsY: number;
  creditsRotation: number;
  showCredits: boolean;
  preview: Media;
  images: Media[];
  customerType: string;
  printLines: PrintLine[];
  cut: string;
  labels: ProductLabel[];
  cutFileId: string;
  createdAt: Date;
  b2b: boolean;

  static customerTypes = [
    { id: "b2c", label: "Cliente finale" },
    { id: "b2b", label: "Rivenditore" }
  ];
}
