import Model from './Model';
import ICustomer from './interfaces/ICustomer';

export default class Customer extends Model implements ICustomer {
  static type = 'customers';
  externalId!: string
  firstName!: string;
  lastName!: string;
  role: string;
  contactsEtag: string;
  contactsResourceName: string;
  subscribedAt: Date;
  email: string;
  avatar: string;
  b2b: boolean;

  get fullname() {
    return `${this.firstName} ${this.lastName}`
  }
}
