import { PrintSide, WarehouseItem } from '@/models';
import Model from './Model';

export class PrintLineConfiguration {
  warehouseItem: WarehouseItem; color: string | null

  constructor(){
    this.warehouseItem = null;
    this.color = null;
    return this;
  }

}

export class PrintLinePayload {
  id: number;
  quantity?: number = 1;
  position?: number;
  printableId?: string;
  printableType?: string;
  printSideId: string;
  url: string;
  file: any;
  configuration?: PrintLineConfiguration;

  static empty() : PrintLinePayload{
    const instance = new PrintLinePayload();
    instance.quantity = 1;
    instance.position = 0;
    instance.file =null;
    instance.printSideId = null;
    instance.printableId = null;
    instance.printableType = null;
    instance.url = null;
    instance.configuration = new PrintLineConfiguration();
    return instance;
  }

  static fromModel(model: PrintLine) : PrintLinePayload{
    const instance = new PrintLinePayload();
    instance.quantity = model.quantity;
    instance.position = model.position;
    instance.printSideId = model.printSide?.id;
    instance.printableId = model.printableId;
    instance.id = model.id;
    instance.url = model.url;
    instance.configuration = model.configuration;
    return instance;
  }
}

export default class PrintLine extends Model{
  static type = 'printLines';

  quantity: number;
  position: number;
  printableId?: string;
  printableType?: string;
  url:string;
  printSide: PrintSide;
  configuration: PrintLineConfiguration
}
