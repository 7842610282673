const routes = [
  {
    path: "/invoicing",
    component: () => import("@/views/Invoicing/Dashboard.vue"),
    name: "invoicing",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },
      {
        path: "/invoicing/list",
        component: () => import("@/views/Invoicing/List.vue"),
        name: "invoice_lines_list"
      }
    ]
  }
];

export default routes;
