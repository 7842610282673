import Model from "./Model";

export class CutFilePayload {
  id?: string = null;
  name?: string = null;
  url?: string = null;
  sourceId?: string = null;

  static fromData(data: CutFile) {
    const item = new CutFilePayload();
    item.name = data.name;
    item.id = data.id;
    item.url = data.url;
  }
}

export default class CutFile extends Model {
  static type = "cutFiles";

  name: string;
  url: string;
}
