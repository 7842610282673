import Model from "./Model";

export class Size {
  width: number = null;
  height: number = null;
}

export class Position {
  x = 0;
  y = 0;
}

export class ProductLabelMetadata {
  size: Size = new Size();
  fields: ProductLabelField[] = [];
}

export class ProductLabelField {
  position: Position = new Position();
  size: Size = new Size();
  fontSize = 14;
  type: "image" | "text" = null;
  column: string = null;
  prefix: string = null;
}

export class ProductLabelPayload {
  id?: string = null;
  quantity?: number = 1;
  metadata?: ProductLabelMetadata = new ProductLabelMetadata();
  url?: string = null;
  cutUrl?: string = null;
  kind?: string = null;
  productId?: string = null;

  static fromData(data: ProductLabel): ProductLabelPayload {
    const item = new ProductLabelPayload();
    item.id = data.id;
    item.url = data.url;
    item.cutUrl = data.cutUrl;
    item.metadata = data.metadata;
    item.kind = data.kind;
    item.quantity = data.quantity;
    return item;
  }
}

export default class ProductLabel extends Model {
  static type = "productLabels";

  quantity: number;
  url: string;
  cutUrl: string;
  kind: string;
  metadata?: ProductLabelMetadata;
}
