import { Order } from '.';
import Model from './Model';
import OrderLine from './OrderLine';
export default class WarehouseDiff extends Model {
  static type = 'warehouseDiffs';

  createdAt: Date;
  value: number;
  moveable: OrderLine|Order;
  moveableType: string;
  name: string;
}
