import { ProductCategory } from ".";
import Model from "./Model";
import StoreFrontCategoryGroup from "./StoreFrontCategoryGroup";

export default class CategoryGroup extends Model {
  static type = "categoryGroups";

  name!: string;
  storeFrontCategoryGroups?: StoreFrontCategoryGroup[];
  categories!: ProductCategory[];
}
