const routes = [
  {
    path: '/labels', component: () => import('@/views/Label/Dashboard.vue'), name: 'labels', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      {
        path: '/labels/list', component: () => import('@/views/Label/List.vue'), name: 'labels_list'
      }
    ]
  }
];

export default routes;
