import Model from "./Model";

export default class StoreFront  extends Model {

  static type = "storeFronts";

  appId: string;
  apiKey: string;
  name: string;
  sourceId: string;
  style: any;
  uuid: string;
}
