import Model from './Model';
import IWebhook from './interfaces/IWebhook';
export default class Webhook extends Model implements IWebhook {
  static type = 'webhooks';

  name: string;
  topic: string;
  triggers: number;
  status: string;

}
