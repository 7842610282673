import StoreFrontModel from '@/models/StoreFront';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'storeFront'
})
export default class StoreFront extends VuexModule {
  private _storeFront: StoreFrontModel | undefined = undefined;

  get storeFront() {
    return this._storeFront;
  }

  @Mutation
  setStoreFront(payload: StoreFrontModel) {
    this._storeFront = payload;
  }
}
