import WarehouseItemRoutes from "./WarehouseItem";
import ManufactureRoutes from "./Manufacture";
import ShippingContract from "./ShippingContract";
import Shipment from "./Shipment";
import Invoicing from "./Invoicing";
import User from "./User";
import CarrierStatus from "./CarrierStatus";

const routes = [
  ...WarehouseItemRoutes,
  ...ManufactureRoutes,
  ...ShippingContract,
  ...Shipment,
  ...CarrierStatus,
  ...Invoicing,
  ...User
];

export default routes;
