const routes = [
  {
    path: "/imports",
    component: () => import("@/views/Import/List.vue"),
    name: "imports",
    meta: { role: ["superadmin", "admin", "manager"] }
  }
];

export default routes;
