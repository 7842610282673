import PrintCategoryRoutes from "./PrintCategory";
import LabelRoutes from "./Label";
import CutFileRoutes from "./CutFile";
import PrintTemplateRoutes from "./PrintTemplate";

const routes = [
  ...PrintCategoryRoutes,
  ...LabelRoutes,
  ...CutFileRoutes,
  ...PrintTemplateRoutes,
  {
    path: "/prints/create",
    component: () => import(/* webpackChunkName: "print" */"@/views/PrintGroup/Create.vue"),
    name: "prints_create"
  },
  {
    path: "/prints/:id/edit",
    component: () => import(/* webpackChunkName: "print" */"@/views/Print/Edit.vue"),
    name: "prints_edit"
  },

  {
    path: "/prints",
    component: () => import(/* webpackChunkName: "print" */"@/views/Print/Dashboard.vue"),
    name: "prints",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },
      {
        path: "/prints/list",
        component: () => import(/* webpackChunkName: "print" */"@/views/PrintGroup/List.vue"),
        name: "prints_list"
      }
    ]
  }
];

export default routes;
