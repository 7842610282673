import { Print, PrintCategory } from ".";
import Model from "./Model";

export class CreatePrintPayload {
  printCategoryId: string = null;
  orders: string[] = [];
  products: { id: string; quantity: number }[] = [];
  images: { file: any; id?: string, quantity: number, url?: string, name?: string }[] = [];

  ordersToPrint: string[] = [];

  get ready() {
    return this.printCategoryId && (this.products?.length || this.orders?.length || this.images?.length)
  }
}

export class CreatePrintGroupPayload{
  sourceId: string = null;
  prints: CreatePrintPayload[] = [];

  get formData() {
    const formData = new FormData();
    formData.append('source_id', this.sourceId);
    formData.append('print_params', JSON.stringify(this))
    formData.append('file', this.prints[0].images[0].file)
    return formData;
  }
}


export default class PrintGroup extends Model {
  static type = "printGroups";

  prints: Print[];
}
