import store from '@/store';

export default class StoreSubscriber {
  static subscribe(mutationName: string, callback: (a: any) => void) {
    return store.subscribe((mutation: any, state: any) => {
      if (mutation.type === mutationName) {
        callback(mutation.payload);
      }
    });
  }
}
