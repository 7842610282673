const routes = [
  // { path: '/carrier_statuses/show/:id', component: () => import('@/views/CarrierStatus/Show.vue'), name: 'carrier_statuses_show'}
// ,
  {
    path: '/carrier_statuses', component: () => import('@/views/CarrierStatus/Dashboard.vue'), name: 'carrier_statuses', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      { path: '/carrier_statuses/list', component: () => import('@/views/CarrierStatus/List.vue'), name: 'carrier_statuses_list'},
    ]
  }
];

export default routes;
