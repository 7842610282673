
















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import HeaderSelect from "@/components/Source/HeaderSelect.vue";
import { User } from "@/models";
import { Source } from "@/models";
import { NoCache } from "@/utils/Decorators";
import Credits from "@/components/Credits.vue";
import { sourceStore } from "@/store/typed";

export interface SidebarItem {
  label: string;
  name: string;
  badge?: string|number;
  locked?: boolean;
  icon?: string;
  disabled?: boolean;
}
export interface SidebarGroup {
  name: string;
  icon?: string;
  items: SidebarItem[];
  alwaysOpen?: boolean;
  roles?: string[];
}

@Component({
  components: {
    HeaderSelect,
    Credits
  }
})
export default class TheSidebar extends Vue {
  @Getter("currentUser") user!: User;

  open = false;
  openedGroup: string|null = null;

  get source() {
    return sourceStore.currentSource;
  }

  toggleGroup(groupName: string) {
    if (this.openedGroup !== groupName) {
      this.openedGroup = groupName;
    } else {
      this.openedGroup = null;
    }
  }

  visible(roles: string[]) {
    return roles?.includes(this.user.role) || !roles?.length;
  }

  @NoCache
  get groups(): SidebarGroup[] {
    return [
      {
        icon: "desktop",
        name: "Ecommerce",
        roles: [...User.adminRoles, ...User.companyRoles, User.externalCollaborator].map(o => o.id),
        alwaysOpen: this.user.role ===  User.externalCollaborator.id,
        items: [
          { label: "Negozio", name: "source_webhooks", icon: "store" },
          {
            label: "Ordini",
            name: "orders_list",
            icon: "shopping-cart",
            badge: this.source?.ordersCount,
            locked: !this.source.synced
          },
          {
            label: "Prodotti",
            name: "products_list",
            badge: this.source?.productsCount,
            icon: "gift",

            locked: !this.source.synced
          },
          {
            label: "Clienti",
            name: "customers_list",
             badge: this.source?.customersCount,
            icon: "user",
            locked: !this.source.synced
          },
          {
            label: "Coupon",
             badge: this.source?.couponsCount,
            name: "coupons_list",
            icon: "percentage",

            locked: !this.source.synced
          }
        ]
      },
      {
        icon: "channel",
        roles: [...User.adminRoles,...User.companyRoles].map(o => o.id),
        name: "Logistica",
        items: [
          { label: "Fatturazione", name: "invoice_lines_list",  icon: "euro", },
          { label: "Magazzino", name: "warehouse_items_list", icon:'analytics' },
          { label: "Lavorazioni", name: "manufactures_list", icon: 'constructor' },
          { label: "Stati corriere", name: "carrier_statuses_list" ,  icon: 'navigator' },
          { label: "Contratti di spedizione", name: "shipping_contracts_list" ,  icon: 'navigator' },
          { label: "Spedizioni", name: "shipments_list",  icon: 'truck'  },
                    { label: "Utenti", name: "users_list",  icon: 'user'  }

        ]
      },
      {
        icon: "circuit",
        roles: [...User.adminRoles,...User.companyRoles].map(o => o.id),
        name: "Automazione",
        items: [
          { label: "Azioni", name: "activities_list", icon: "bolt" },
          { label: "Triggers", name: "trigger_activities_list", icon: "rocket" },
          { label: "API Keys", name: "api_keys", icon: "arrow" },
          { label: "Imports", name: "imports", icon: "file-import" },
          { label: "Exports", name: "exports", icon: "file-export" },
        ]
      },
      {
        icon: "print",
        name: "Stampa",
        roles: [...User.adminRoles,...User.companyRoles].map(o => o.id),
        items: [
          {
            label: "Categorie di stampa",
            name: "print_categories_list",
            icon: 'image-resize-landscape'
          },
          {
            label: "Template di stampa",
            name: "print_templates_list",
            icon: 'object-group'
          },
          { label: "Etichette", name: "labels_list", icon: 'parcel' },
          { label: "File di taglio", name: "cut_files_list", icon: 'ruler' },
          { label: "Stampe", name: "prints_list", icon: 'table' }
        ]
      },
         {
        icon: "data-sharing",
        name: "Integrazioni",
        roles: [...User.adminRoles,...User.companyRoles].map(o => o.id),
        items: [
          {
            label: "App",
            name: "store_fronts_list",
            icon: 'mobile-android'
          }
        ]
      }
    ];
  }

  get sidebarOpen() {
    return this.isTablet && !this.open;
  }

  get style() {
    return this.isTablet && this.open ? { "z-index": 2000 } : {};
  }

  get isSuperAdmin(): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.isSuperAdmin;
  }

  get isTablet() {
    return this.$mq === "sm" || this.$mq === "md" || this.$mq === "lg";
  }
}
