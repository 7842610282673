import Vue from 'vue';

export function showToast(message: string, config: {position?: string; type?: string; duration?: number} = {position: 'bottom-right', type: 'success', duration: 500}) {

  Vue.toasted.show(message, {
    position: config.position || 'bottom-right',
    type: config.type || 'success',
    icon: null,
    duration: config.duration || 1000,
    keepOnHover: true
  } as any);
}

export function showErrorToast(message?: string, config: {position?: string; duration?: number} = {position: 'bottom-right', duration: 500}) {
  const defaultMsg = "Si è verificato un errore";

  Vue.toasted.show(message || defaultMsg, {
    position: config.position || 'bottom-right',
    type: 'error',
    icon: null,
    duration: config.duration || 1000,
    keepOnHover: true
  } as any);
}
