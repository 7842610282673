import VueRouter, { RouteConfig } from "vue-router";
import User from "@/models/User";
import AuthenticationRoutes from "./Authentication";
import SourceRoutes from "./Source";
import LogisticsRoutes from "./Logistics";
import PrintRoutes from "./Print";
import AutomationRoutes from "./Automation";
import IntegrationsRoutes from "./Integrations";
import StoreFrontAuthenticationRoutes from "./StoreFrontAuthentication";
import StoreFrontPanelRoutes from "./StoreFrontPanel";
import { authStore } from "@/store/typed";

// Roles: ['admin', 'manager', 'executive', 'agent']

const routes: RouteConfig[] = [
  { path: "/", redirect: "secure", name: "root", meta: { role: ["anyone"] } },
  {
    path: "/secure",
    component: () => import("@/views/Container.vue"),
    name: "secure",
    meta: { role: ["anyone"] },
    children: [
      {
        path: "/",
        redirect: "dashboard",
        name: "secure_root",
        meta: { role: ["anyone"] }
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { role: ["anyone"] }
      },
      ...SourceRoutes,
      ...LogisticsRoutes,
      ...PrintRoutes,
      ...AutomationRoutes,
      ...IntegrationsRoutes
    ]
  },
  {
    path: "/forgot_password",
    component: () => import("@/views/Password/Forgot.vue"),
    name: "forgot_password",
    meta: { noAuth: true }
  },
  {
    path: "/reset_password",
    component: () => import("@/views/Password/Reset.vue"),
    name: "reset_password",
    meta: { noAuth: true }
  },
  ...AuthenticationRoutes,
  ...StoreFrontAuthenticationRoutes,
  ...StoreFrontPanelRoutes,
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
    name: "404",
    meta: { role: ["anyone"] }
  }
];

const router = new VueRouter({
  base: "/",
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const { role, noAuth } = to?.matched[0]?.meta || {};
  authStore.authenticate().then(
    (user: User) => {
      if (to?.matched?.some(record => record.meta.noAuth)) {
        next("/");
        return;
      }

      if (role?.includes(user.role) || role?.includes("anyone")) {
        // if authorized

        if (to.name === "dashboard") {
          next("/orders");
          return;
        }

        next();
        return;
      } else {
        // if not it is redirected
        next(false);
        return;
      }
    },
    () => {
      if (to?.matched?.every(record => record.meta.noAuth)) {
        next();
        return;
      } else {
        next("/login");
      }
    }
  );
});

export default router;
