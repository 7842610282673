const routes = [
  {
    path: '/print_categories', component: () => import('@/views/PrintCategory/Dashboard.vue'), name: 'print_categories', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      {
        path: '/print_categories/list', component: () => import('@/views/PrintCategory/List.vue'), name: 'print_categories_list'
      },

    ]
  },
  {
    path: '/print_categories/:id', component: () => import('@/views/PrintCategory/Show.vue'), name: 'print_category_show'
  }
];

export default routes;
