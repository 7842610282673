const routes = [
  { path: '/print_templates/show/:id', component: () => import('@/views/PrintTemplate/Show.vue'), name: 'print_templates_show' },
  {

    path: '/print_templates', component: () => import('@/views/PrintTemplate/Dashboard.vue'), name: 'print_templates', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      {
        path: '/print_templates/list', component: () => import('@/views/PrintTemplate/List.vue'), name: 'print_templates_list'
      }
    ]
  }
];

export default routes;
