import { Model } from "@/models";
import {v1 as uuid} from 'uuid';

export class ApiKeyPayload {
  id: string;
  key: string;
  name: string;
  scopes: Array<string>;
  sourceId: string;

  static empty() {
    const payload = new ApiKeyPayload();

    payload.id = '';
    payload.key = uuid();
    payload.name = '';
    payload.scopes = [''];
    payload.sourceId = '';

    return payload;
  }

  static fromModel(model: ApiKey) {
    const payload = new ApiKeyPayload();

    payload.id = model.id;
    payload.key = model.key;
    payload.name = model.name;
    payload.scopes = model.scopes;
    payload.sourceId = model.sourceId;

    return payload;
  }
}

export default class ApiKey extends Model {

  static type="apiKeys"

  key: string;
  name: string;
  scopes: Array<string>;
  sourceId: string;
}
