import Model from "./Model";

export class DiscountPayload {
  id?: string = null;
  kind?: string = null;
  rules?: any = [];
  storeFrontId?: string = null;

  static fromData(data: Discount) {
    const item = new DiscountPayload();
    item.id = data.id;
    item.kind = data.kind;
    item.storeFrontId = data.storeFrontId;
    item.rules = data.rules;
    return item;
  }
}

export default class Discount extends Model {
  static type = "discounts";

  kind: string;
  rules: any;
  storeFrontId?: string;
}
