import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import SourceModel from '@/models/Source';

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'source'
})
export default class Source extends VuexModule {
  private _currentSource: SourceModel | undefined = undefined;

  get currentSource() {
    return this._currentSource;
  }

  get company() {
    return this._currentSource.company;
  }

  @Mutation
  setCurrentSource(payload: SourceModel | undefined) {
    this._currentSource = payload;
  }

  @Action({ rawError: true })
  dispatchSetCurrentSource(payload: SourceModel) {
    const { commit } = this.context;

    commit('setCurrentSource', payload);
  }
}
