import { Activity } from ".";
import Model from "./Model";
import { OrderStatus } from "./";

export default class TriggerActivity extends Model  {
  static type = "triggerActivities";

  activity: Activity;
  activityId: string;
  triggerable: OrderStatus;
  triggerableType: string;
  triggerableName: string;
  triggerableId: string;

  static types: {id: string, label: string, }[] = [{
    id: 'OrderStatus', label: 'Aggiornamento stato ordine',
  },{id: 'CarrierStatus', label: 'Aggiornamento stato corriere',
},{
    id: 'SendEmail', label: 'Invio email'
  }]

  get formattedType() {
    return TriggerActivity.types.find(o => o.id === this.triggerableType)?.label
  }

  get triggeredByOrderStatus() {
    return this.triggerableType === 'OrderStatus';
  }

  get triggeredBySendEmail() {
    return this.triggerableType === 'SendEmail';
  }

}
