import Model from "./Model";
import IAddress from "./interfaces/IAddress";

export default class Address extends Model implements IAddress {
  static type = "addresses";

  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  phone: string;
  email: string;

  get fullname() {
    return `${this.firstName} ${this.lastName}`;
  }

  get address() {
    return this.address1 || this.address2;
  }
}
