






import { Component, Vue } from "vue-property-decorator";
import Container from "@/views/Container.vue";
import { Getter } from "vuex-class";
import { User } from "@/models";

@Component({ components: { Container } })
export default class App extends Vue {
  @Getter currentUser: User;

  get blueBg() {
    return this.$isRouteActive("login") ||
      this.$isRouteActive("forgot_password") ||
      this.$isRouteActive("reset_password")
      ? "bg-dark"
      : "";
  }
}
