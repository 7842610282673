import { BASE_API_URL } from '@/utils/Constants';
import {Component, Vue} from 'vue-property-decorator';
import qs from "qs"
import { httpStore } from '@/store/typed';
@Component
export default class RequestsMixin extends Vue {


  $getRoute(path: string) {
    return BASE_API_URL + path;
  }

  $getModelRoutes(path: string) {
    path = BASE_API_URL + path;
    const data = {
      getUrl: (
        id: string,
        opts?: { fields?: { [key: string]: string }; include?: string }
      ) => {
        const params = qs.stringify(
          { fields: opts?.fields, include: opts?.include },
          { arrayFormat: "brackets" }
        );
        const query = params.length > 0 ? "?" + params : "";

        return path + "/" + id + query;
      },
      createUrl: () => path,
      indexUrl: (params: any) =>
        path + "?" + qs.stringify(params, { arrayFormat: "brackets" }),
      updateUrl: (id: string) => path + "/" + id,
      deleteUrl: (id: string) => path + "/" + id
    };

    return data;
  }

  $httpGetQuery(url: string, tag: string, params: any={}) {
    if(params.length || Object.keys(params).length){
      const query = qs.stringify(params, { arrayFormat: "brackets" })

      if(query.length){
        url = `${url}?${query}`
      }
    }

    return httpStore.request({
      tag: tag,
      url: url,
      method: 'GET'
    });
  }

  $httpGet(url: string, tag: string) {
    return httpStore.request({
      tag: tag,
      url: url,
      method: 'GET'
    });
  }

  public $httpPost(url: string, tag: string, data: any) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: 'POST'
    });
  }

  public $httpPut(url: string, tag: string, data: any) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: 'PUT'
    });
  }

  public $httpDelete(url: string, tag: string) {
    return httpStore.request({
      tag: tag,
      url: url,
      method: 'DELETE'
    });
  }
}
