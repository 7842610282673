import { Banner, Model } from ".";

export interface BannerGroupPayload {
  kind: string;
  pageCode: string;
  storeFrontId: string;
}

export default class BannerGroup extends Model {
  static type = 'bannerGroups';

  kind!: string;
  pageCode!: string;
  banners!: Banner[];
}