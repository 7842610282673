const routes = [
  {
    path: '/manufacture', component: () => import('@/views/Manufacture/Dashboard.vue'), name: 'manufacture', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list'},
      {

        path: '/manufacture/list', component: () => import('@/views/Manufacture/List.vue'), name: 'manufactures_list'
      }
    ]
  }
];

export default routes;
