import Model from './Model';

export default class Design extends Model {
  static type = 'designs';
  printSideId: string;
  designId: string;
  printLineId: string;
  url: string;

}
