import Model from "./Model";
import Product from "./Product";
import Design from "./Design";
import WarehouseItem from "./WarehouseItem";
import Order from "./Order";
import OrderLineWarehouseItem from "./OrderLineWarehouseItem";
export default class OrderLine extends Model{
  static type = "orderLines";

  orderExternalId: string;
  externalId: string;
  orderId: string;
  name: string;
  quantity: number;
  imagesCount: number;
  importer: string;
  approved: boolean;
  designs: Design[];
  product: Product;
  printStatus: string;
  importStatus: string;

  orderLines: OrderLine[];
  orderLineWarehouseItems: OrderLineWarehouseItem[];
  order: Order;

  get designUrls() {
    return this.designs.map(o => o.url);
  }

  get isCustomizable() {
    return this.importer=='zakeke';
  }

  get pieces() {
    return this.orderLines.reduce((a,b) => a + b.quantity, 0);
  }
}
