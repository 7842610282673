import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faLink,
  faHeading,
  faExclamation,
  faImage,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faExclamationCircle,
  faChevronUp,
  faSpinner,
  faCheckCircle,
  faPlus,
  faStore,
  faPrint,
  faPercent,
  faEuroSign,
  faUser,
  faMoneyBill,
  faEdit,
  faTrash,
  faCircleNotch,
  faLock,
  faCloudDownloadAlt,
  faShoppingBag,
  faDownload,
  faTruck,
  faFileDownload,
  faTimes,
  faPhone,
  faShoppingBasket,
  faGifts,
  faFileContract,
  faToolbox,
  faWarehouse,
  faScrewdriver,
  faEnvelope,
  faStickyNote,
  faBorderAll,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

import {} from "@fortawesome/free-brands-svg-icons";

library.add(
  faChevronDown as any,
  faDownload as any,
  faSpinner as any,
  faScrewdriver as any,
  faBorderAll as any,
  faStickyNote as any,
  faFileContract as any,
  faToolbox as any,
  faChevronRight as any,
  faWarehouse as any,
  faEuroSign as any,
  faShoppingBasket as any,
  faUser as any,
  faGifts as any,
  faMoneyBill as any,
  faChevronLeft as any,
  faStore as any,
  faShoppingBag as any,
  faPrint as any,
  faExclamationCircle as any,
  faFileDownload as any,
  faCheckCircle as any,
  faCloudDownloadAlt as any,
  faChevronUp as any,
  faTruck as any,
  faPercent as any,
  faPhone as any,
  faPaperPlane as any,
  faLink as any,
  faHeading as any,
  faImage as any,
  faCheck as any,
  faPlus as any,
  faExclamation as any,
  faEdit as any,
  faTrash as any,
  faEnvelope as any,
  faPaperPlane as any,
  faCircleNotch as any,
  faTimes as any,
  faLock as any
);
