import Model from "./Model";
import IUser from "./interfaces/IUser";
import Company from "./Company";
import SourceUser from "./SourceUser";

export default class User extends Model implements IUser {
  static type = "users";

  static companyRoles: Array<{ name: string; id: string }> = [
    { name: "Admin azienda", id: "company_admin" },
    { name: "Utente azienda", id: "company_user" }
  ];

  static creatableRoles: Array<{ name: string; id: string }> = [
    { name: "Utente azienda", id: "company_user" },
    { name: "Collaboratore", id: "external_collaborator" }
  ];

  static externalCollaborator =  { name: "Collaboratore", id: "external_collaborator" }

  static adminRoles: Array<{ name: string; id: string }> = [
    { name: "SuperAdmin", id: "superadmin" },
  ];
  static roles: Array<{ name: string; id: string }> = [
    ...User.companyRoles,
    ...User.adminRoles,
    User.externalCollaborator

  ];

  email: string;
  firstName: string;
  lastName: string;
  role: string;
  company: Company;
  sourceUsers: SourceUser[];
  sourceIds: string[];

  fullname(): string {
    return this.firstName + " " + this.lastName;
  }

  static getRoleLabel(role: string): string | undefined {
    return this.roles.find(o => o.id === role)?.name;
  }

  isOneOf(roles: string[]) {
    return roles.includes(this.role);
  }

  get isAdminRole() {
    return !!User.adminRoles.find(o => o.id === this.role);
  }

  get isCompanyRole() {
    return !!User.companyRoles.find(o => o.id === this.role);
  }

  get isSuperAdmin() {
    return this.role === "super_admin";
  }

  get isCompanyAdmin() {
    return this.role === "company_admin";
  }

  get isCompanyUser() {
    return this.role === "company_user";
  }

  get isCollaborator() {
    return this.role === "external_collaborator";
  }
}
