import Model from './Model';

export default class PrintSide extends Model{
  static type = 'printSides';

  importerCode: string;
  name: string;
  printCategoryId: string;

}
