const routes = [
  { path: '/discounts/show/:id', component: () => import('@/views/StoreFront/Panel/Discount/Show.vue'), name: 'store_front_discounts_show'}
,
  {
    path: '/discounts', component: () => import('@/views/StoreFront/Panel/Discount/Dashboard.vue'), name: 'store_front_discounts', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      { path: '/discounts/list', component: () => import('@/views/StoreFront/Panel/Discount/List.vue'), name: 'store_front_discounts_list'},

    ]
  }
];

export default routes;

