import Model from "./Model";
import ISource from "./interfaces/ISource";
import { Company, User } from ".";
import { FormItem } from "./ViewModels";

export default class Source extends Model implements ISource {
  static type = "sources";
  name!: string;
  user!: User;
  type!: string;
  invoicingEnabled: boolean;
  needsApproval: boolean;
  syncedAt: Date;
  diffCheckedAt: Date;
  couponsCount: number;
  printOrderStatusId: string;
  pendingOrderStatusId: string;
  cancelOrderStatusId: string;
  completeOrderStatusId: string;
  discardedOrderStatusId: string;
  approvedOrderStatusId: string;
  inProgressOrderStatusId: string;
  shippedOrderStatusId: string;
  productsCount: number;
  customersCount: number;
  ordersCount: number;
  configuration: any;
  company: Company;
  ordersFee: number;
  logoUrl: string;
  shipmentSuffix: string;

  get activated() {
    return true;
  }

  get synced() {
    return !!this.syncedAt;
  }

  get fields(): FormItem[] {
    return [{ label: "Nome", type: "string", id: "name" }];
  }
}
