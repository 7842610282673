const routes = [
  {
    path: "/orders",
    component: () => import(/* webpackChunkName: "source" */ "@/views/Order/Dashboard.vue"),
    name: "orders",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },

      {
        path: "/orders/list",
        component: () => import(/* webpackChunkName: "source" */ "@/views/Order/List.vue"),
        name: "orders_list"
      }
    ]
  },
  {
    path: "/orders/:id",
    component: () => import(/* webpackChunkName: "source" */ "@/views/Order/Show.vue"),
    name: "orders_show"
  }
];

export default routes;
