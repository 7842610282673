import ActivityRoutes from "./Activity";
import TriggerActivityRoutes from "./TriggerActivity";
import ApiKeyRoutes from "./ApiKey";
import ImportRoutes from './Import';
import ExportRoutes from "./Export";

const routes = [
  ...ActivityRoutes,
  ...TriggerActivityRoutes,
  ...ApiKeyRoutes,
  ...ImportRoutes,
  ...ExportRoutes
];

export default routes;
