import { Label, PrintablePrintTemplate } from '.';
import Model from './Model';
import { FormItem } from './ViewModels';

export class PrintTemplatePayload {
  name: string = null;
  rows: number = null;
  columns: number = null;
  width: number = null;
  companyId: string = null;
  height: number = null;
  imageWidth: number = null;
  imageHeight: number = null;
}

export default class PrintTemplate extends Model {
  static type = 'printTemplates';

  name: string;
  rows: number;
  columns: number;
  width: number;
  height: number;
  companyId: string;
  imageWidth: number;
  imageHeight: number;
  printablePrintTemplates: PrintablePrintTemplate[];
  labels: Label[];

  get fields(): FormItem[] {
    return [{ id: 'name', label: 'Name', type: 'string' },
    { id: 'rows', label: 'Righe', type: 'number' },
    { id: 'columns', label: 'Colonne', type: 'number' },
    { id: 'width', label: 'Larghezza', type: 'number' },
    { id: 'height', label: 'Altezza', type: 'number' },
    { id: 'imageWidth', label: 'Larghezza immagine', type: 'number' },
    { id: 'imageHeight', label: 'Altezza immagine', type: 'number' }]
  }
}
