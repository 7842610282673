import Model from './Model';
import ICoupon from './interfaces/ICoupon';

export default class Coupon extends Model implements ICoupon {
  static type = 'coupons';
  externalId!: string;
  usageLimit: number;
  code: string;
  amount: number;
  discount_type: string
}
