const routes = [
  {
    path: "/shipping_contract",
    component: () => import("@/views/ShippingContract/Dashboard.vue"),
    name: "shipping_contract",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },
      {
        path: "/shipping_contract/list",
        component: () => import("@/views/ShippingContract/List.vue"),
        name: "shipping_contracts_list"
      }
    ]
  }
];

export default routes;
