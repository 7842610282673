import BannerGroupRoutes from './Content/BannerGroup';
import CategoryGroupRoutes from './Content/CategoryGroup';
import SectionRoutes from './Content/Section';

const routes = [
  {
    path: '/store_fronts/panel/:id/contents',
    component: () => import('@/views/StoreFront/Panel/Content/Index.vue'),
    name: 'store_front_contents',
    meta: { role: ['superadmin'] },
    children: [
      {
        path: '/store_fronts/panel/:id/contents/dashboard',
        component: () => import('@/views/StoreFront/Panel/Content/Dashboard.vue'),
        name: 'store_front_contents_dashboard',
        meta: { role: ['superadmin'] },
      },
      ...BannerGroupRoutes,
      ...CategoryGroupRoutes,
      ...SectionRoutes
    ]
  }
];

export default routes;
