import bannerRoutes from './Banner';

const routes = [
  {
    path: 'banner_groups',
    component: () => import('@/views/StoreFront/Panel/Content/BannerGroup/Index.vue'),
    name: 'banner_groups',
    meta: { role: ['superadmin'] },
    children: [
      {
        path: 'list',
        component: () => import('@/views/StoreFront/Panel/Content/BannerGroup/List.vue'),
        name: 'banner_groups_list',
        meta: { role: ['superadmin'] },
      },
      {
        path: ':bannerGroupId',
        component: () => import('@/views/StoreFront/Panel/Content/BannerGroup/Show/Index.vue'),
        name: 'banner_group_show_index',
        meta: { role: ['superadmin'] },
        children: [
          {
            path: '',
            component: () => import('@/views/StoreFront/Panel/Content/BannerGroup/Show/Show.vue'),
            name: 'banner_group_show',
            meta: { role: ['superadmin'] },
          },
          ...bannerRoutes
        ]
      }
    ]
  }
];

export default routes;
