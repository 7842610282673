import StoreFrontRoutes from "./StoreFront";

const routes = [


    ...StoreFrontRoutes
];


export default routes;
