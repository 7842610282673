import { getModule } from "vuex-module-decorators";
import store from "@/store";

import Http from "@/store/http";
import CurrentUser from '@/store/currentUser';
import Auth from '@/store/auth';
import Source from '@/store/source';
import StoreFront from '@/store/storeFront';

export const currentUserStore: CurrentUser = getModule(CurrentUser, store);
export const httpStore: Http = getModule(Http, store);
export const authStore: Auth = getModule(Auth, store);
export const sourceStore: Source = getModule(Source, store);
export const storeFrontStore: StoreFront = getModule(StoreFront, store);
