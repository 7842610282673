




import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { easync } from "@/utils/http";
import { datesRange } from "@/utils/Date";

@Component({})
export default class VSvgImage extends Vue {
  @Prop({ required: true }) readonly src!: string;
  @Prop({ default: 100 }) size: number;

  private content = "";

  @Watch("src")
  onSrcChanged() {
    this.download();
  }
  get style() {
    return {
      height: `${this.size}px`,
      width: `${this.size}px`
    };
  }

  mounted() {
    this.download();
  }

  async download() {
    const [data, errors] = await easync(axios(this.src));

    if (data) {
      this.content = data.data
    }
  }
}
