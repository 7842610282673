import Model from './Model';
import IWarehouseItem from './interfaces/IWarehouseItem';
import { FormItem } from './ViewModels';
import { WarehouseDiff } from '.';
export default class WarehouseItem extends Model implements IWarehouseItem {
  static type = 'warehouseItems';

  name: string;
  sku: string;
  quantity: number;
  snapshot: number;
  colors: string[];
  companyId: string;
  warehouseDiffs: WarehouseDiff[];

  get fields(): FormItem[] {
    return [{ label: 'Nome', type: 'string', id: 'name' },
    {  label: 'Sku', type: 'string', id: 'sku' },
    {  label: 'Quantità', type: 'number', id: 'quantity' },
    ];
  }
}
