





































import { Component, Prop, Mixins, Vue, Watch } from "vue-property-decorator";
import VEntryList, { VEntryInterface } from "./VEntryList.vue";
import ClickOutside from "../directives/ClickOutside";
import Throttler from "@/utils/Throttler";

const DEFAULT_PLACEHOLDER = "Cerca...";
const DEFAULT_MIN = 3;
const DEFAULT_DELAY = 750;

export interface VEntrySearchResult {
  items: VEntryInterface[];
  total: number;
}

@Component({ components: { VEntryList }, directives: { ClickOutside } })
export default class VEntrySearch extends Vue {
  @Prop({ default: '' }) readonly value!: string;
  @Prop({ default: DEFAULT_PLACEHOLDER }) readonly placeholder!: string;
  @Prop({ default: DEFAULT_MIN }) readonly min!: number;
  @Prop({ default: DEFAULT_DELAY }) readonly delay!: number;
  @Prop({ default: false }) readonly selectable!: boolean;
   @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: "right" }) readonly iconAlign!: string;
  @Prop({ default: false }) readonly withIcon!: boolean;
  @Prop({ required: true }) readonly load!: (
    query: string
  ) => Promise<VEntryInterface[]>;

  term = "";
  timeout: number | undefined;

  items: VEntryInterface[] = [];

  searching = false;

  dropdownVisible = false;

  done = false;

  created() {
    if (this.value) {
      this.term = this.value;
    }
  }

  @Watch('value')
  onValueChanged() {
    if (this.value) {
      this.term = this.value;
    } else {
      this.term = '';
    }
  }

  get results(): VEntrySearchResult {
    return {
      items: this.items || [],
      total: this.items?.length || 0
    };
  }

  async search() {
    this.searching = true;
    await this.searchAll();
    this.searching = false;
    this.$emit("searched", this.results);

    if (this.selectable) {
      this.showDropdown();
    }
  }

  async searchItems() {
    this.items = await this.load(this.term);
  }

  public focus() {
    (this.$refs.input as HTMLInputElement).focus();
  }

  searchAll() {
    return this.searchItems();
  }

  clearSearch() {
    this.items = [];
    this.term = "";
  }

  @Watch("term")
  onTermChanged(val: string, newVal: string) {
    if (this.term === "") {
      this.search();
    }
  }

  emitResults() {
    this.$emit("searched", this.results || []);
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  cancel() {
    this.clearSearch();
    this.done = false;
    this.$emit("cancel");
  }

  protected showDropdown() {
    this.dropdownVisible = true;
  }

  protected closeDropdown() {
    this.dropdownVisible = false;
  }

  protected onKeyUp() {
    this.done = false;
    if (this.min && this.term.length >= this.min) {
      this.clearTimeout();

      this.timeout = window.setTimeout(() => {
        if (this.min && this.term.length >= this.min) {
          this.search();

          if (!this.selectable) {
            this.done = true;
          }
        }
      }, this.delay);
    } else if (this.term.length == 0) {
      this.clearSearch();
      this.done = false;
      this.$emit("cancel");
    }
  }

  protected onSelected(value: VEntryInterface) {
    this.term = value.name;
    this.done = true;
    this.$emit("selected", { ...value });
    this.closeDropdown();
  }
}
