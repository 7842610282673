/* eslint no-console: 0 */
// import 'core-js/modules/es7.promise.finally';
import Vue from "vue";
import App from "./App.vue";
import "@/styles/app.scss";
import Toasted from "vue-toasted";
import VueRouter from "vue-router";
import RoutingMixin from "@/mixins/Routing";
import ModelParserMixin from "@/mixins/ModelParser";
import RouteHelpersMixin from "@/mixins/RouteHelpers";
import RequestsMixin from "@/mixins/http/RequestsMixin";
import DisplayErrors from "@/components/DisplayErrors.vue";
import VSvgImage from "@/components/VSvgImage.vue";
import VImage from "@/components/VImage.vue";
import ContentLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "@/initializers";
import router from "@/router";
import store from "@/store";
import VIcon from "@/components/VIcon.vue";
import VCircleIcon from "@/components/VCircleIcon.vue";
import EmptyView from "@/components/EmptyView.vue";
import Clipboard from 'v-clipboard'
import infiniteScroll from "vue-infinite-scroll";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(infiniteScroll);
Vue.use(Clipboard)

declare module "vue/types/vue" {
  interface Vue {
    $urlFor(name: string, ...args: any[]): any;
    $httpGet(url: string, tag: string): any;
    $httpGetQuery(url: string, tag: string, data:any): any;
    $httpPost(url: string, tag: string, data: any): any;
    $httpPut(url: string, tag: string, data: any): any;
    $httpDelete(url: string, tag: string):any;
    $isRouteActive(route: string): boolean;
    $areRouteActive(routes: string[]): boolean;
    $mq: any;
  }
}
Vue.filter("currency", (value: string) => {
  return "€" + parseFloat(value).toFixed(2);
});
Vue.use(VueRouter);
window.addEventListener("load", () => {
  Vue.use(Toasted);
});
Vue.component("v-icon", VIcon);
Vue.component("v-circle-icon", VCircleIcon);
Vue.component("svg-img", VSvgImage);
Vue.component("v-img", VImage);
Vue.component("display-errors", DisplayErrors);
Vue.component("content-loading", ContentLoading);
Vue.component("empty-view", EmptyView);
Vue.mixin(RoutingMixin);
Vue.mixin(ModelParserMixin);
Vue.mixin(RouteHelpersMixin);
Vue.mixin(RequestsMixin);


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("fa-icon", FontAwesomeIcon);

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.FRONTEND_URL, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount();
  document.body.appendChild(app.$el);
});
