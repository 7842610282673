





import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { easync } from "@/utils/http";

@Component({})
export default class VImage extends Vue {
  @Prop({ required: true }) readonly src!: string;
  @Prop({ default: 0 }) readonly size: number;
  @Prop() readonly innerClass: string;
  @Prop() forceShowImage: boolean;
  @Prop({ default: "cover" }) objectFit: "contain" | "cover" | "fill";

  private content = "";

  @Watch("src")
  onSrcChanged() {
    if (this.isSvg) {
      this.download();
    }
  }

  get style() {
    const style: any = {
      "object-fit": this.objectFit
    };

    if (this.size) {
      style.height = `${this.size}px`;
      style.width = `${this.size}px`;
    } else {
      style.width = "100%";
    }
    return style;
  }

  get isSvg() {
    if (this.forceShowImage) {
      return false;
    }
    const chunks = this.src.split(".");
    return chunks[chunks.length - 1] === "svg";
  }

  mounted() {
    if (this.isSvg) {
      this.download();
    }
  }

  async download() {
    const [data, errors] = await easync(axios(this.src));

    if (data) {
      this.content = data.data
    }
  }
}
