

























import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoWithFallback from '@/components/LogoWithFallback.vue';

export interface VEntryInterface {
  id: string;
  name: string;
  image?: string;
}

@Component
export default class VEntryList extends Vue {
  @Prop() title? : string;
  @Prop({default: 'small'}) size? : string;
  @Prop() items!: VEntryInterface[];
  @Prop() canAdd!: any;
  @Prop({ default: true }) actions? : boolean;
  @Prop({ default: false }) selectable? : boolean;

  add(value: VEntryInterface) {
    this.$emit('add', value);
  }

  remove(value: VEntryInterface) {
    this.$emit('remove', value);
  }

  select(value: VEntryInterface) {
    if (this.selectable === false) {
      return;
    }

    this.$emit('selected', value);
  }

  getWrapperClass() {
    const classes: string[] = [];

    classes.push(`entry-list--${this.size}`);

    if (this.selectable) {
      classes.push('entry-list--selectable');
    }

    return classes;
  }
}
