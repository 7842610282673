import { CategoryGroup, Model, StoreFront } from ".";
import StoreFrontCategoryGroup from "./StoreFrontCategoryGroup";

export interface StoreFrontSectionPayload {
  title: string;
  subtitle?: string;
  storeFrontId: string;
  pageCode: string;
}

export default class StoreFrontSection extends Model {
  static type = 'storeFrontSections';

  categoryGroups!: CategoryGroup [];
  title!: string;
  subtitle!: string;
  pageCode!: string;
  storeFrontId!: string;

  payloadFromModel(): StoreFrontSectionPayload {
    const payload: StoreFrontSectionPayload = {
      title: this.title,
      subtitle: this.subtitle,
      storeFrontId: this.storeFrontId,
      pageCode: this.pageCode
    }

    return payload;
  }
}