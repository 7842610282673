const routes = [
  {
    path: "/exports",
    component: () => import("@/views/Export/List.vue"),
    name: "exports",
    meta: { role: ["superadmin", "admin", "manager"] }
  }
];

export default routes;
