import { InvoiceLineManufacture, Order, OrderLine } from ".";
import Model from "./Model";

export default class InvoiceLine extends Model {
  static type = "invoiceLines";

  total: number;
  name: string;
  createdAt: Date;
  invoiceable: OrderLine | Order;
  invoiceableType: string;
  invoiceableExternalId: string;
  invoiceLineManufactures: InvoiceLineManufacture[];

  isInvoicableA(type: string) {
    const split = this.invoiceableType.split("::");
    return split[split.length - 1] === type;
  }

  get manufactures() {
    if (this.isOrderLine) {
      return this.invoiceLineManufactures
        .map(o => `${o.manufacture.name} (${o.manufacture.total}€/pz)`)
        .join(", ");
    } else {
      return "Spese logistiche";
    }
  }

  get isOrder() {
    return this.isInvoicableA("Order");
  }

  get isOrderLine() {
    return this.isInvoicableA("OrderLine");
  }

  get externalOrderId() {
    if (this.isOrderLine) {
      return (this.invoiceable as OrderLine).orderExternalId;
    } else {
      return (this.invoiceable as Order).externalId;
    }
  }

  get orderId() {
    if (this.isOrderLine) {
      return (this.invoiceable as OrderLine).orderId;
    } else {
      return (this.invoiceable as Order).id;
    }
  }

  get totalPieces() {
    if (this.isOrderLine) {
      return (this.invoiceable as OrderLine).imagesCount || "-";
    } else {
      return "-";
    }
  }
}
