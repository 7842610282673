






import { Component, Prop, Vue } from 'vue-property-decorator';
import ColorHelper from '@/utils/ColorHelper';

@Component
export default class VLetterIcon extends Vue {
	@Prop() color? : string|number;

	@Prop() value: string;

  // get style() {
  //   let color = '';

  //   if(this.color) {
  //     color = typeof this.color === 'string' ? this.color : ColorHelper.colorAtIndex(this.color as number);
  //   } else {
  //     color = ColorHelper.random();
  //   }

  //   return {
  //     'backgroundColor': color || ''
  //   };
  // }
}
