import Model from "./Model";

export default class Activity extends Model  {
  static type = "activities";

  static types: {id: string, label: string, type: string, icon: string}[] = [{
    icon: 'data-sharing',id: 'activityUpdateOrderStatuses', type: 'Activity::UpdateOrderStatus', label: 'Aggiornamento stato ordine',
  },{ icon: 'envelope-send',
    id: 'activitySendEmails', label: 'Invio email',type: 'Activity::SendEmail'
  }, { icon: 'data-sharing',
  id: 'activityUpdateShipmentStatuses', label: 'Aggiornamento stato spedizione',type: 'Activity::UpdateShipmentStatus'
}]
  name: string;
  status: string;
  meta: any;
  type: string;

  get formattedType() {
    return Activity.types.find(o => o.type === this.type)
  }

  get isUpdateOrderStatus(){
    return this.type === 'Activity::UpdateOrderStatus';
  }
  get isUpdateShipmentStatus(){
    return this.type === 'Activity::UpdateShipmentStatus';
  }
  get isSendEmail(){
    return this.type === 'Activity::SendEmails';
  }


}
