import Model from "./Model";

export default class ImporterType extends Model {
  static type = "importerTypes";

  type: string;
  label: string;
  fileRequired: boolean;
  fileFormat: string;
}
