


































import { Vue, Component } from "vue-property-decorator";
import LetterIcon from "@/components/VLetterIcon.vue";
import { Getter } from "vuex-class";
import store from "../store";
import { User } from "@/models";
import ClickOutside from "@/directives/ClickOutside";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { authStore } from "@/store/typed";

@Component({ components: { LetterIcon }, directives: { ClickOutside } })
export default class TopbarDropdown extends Vue {
  @Getter currentUser: User;

  private open = false;

  get letters() {
    return `${this.currentUser.firstName
      .charAt(0)
      .toUpperCase()}${this.currentUser.lastName.charAt(0).toUpperCase()}`;
  }

  async _logout() {
    authStore.logout();
    window.location.reload();
  }

  get role() {
    return User.roles.find(o => o.id === this.currentUser.role)?.name || "";
  }

  protected close() {
    this.open = false;
  }
}
