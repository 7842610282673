import * as WebFont from "webfontloader";

WebFont.load({
  google: {
    families: [
      "Raleway:100,300,400,600,700",
      "Bungee Shade:100,300,400,600,700",
      "Poppins:100,300,400,600,700"
    ]
  }
});
