const routes = [
  {
    path: "/activity",
    component: () => import("@/views/Activity/Dashboard.vue"),
    name: "activity",
    meta: { role: ["superadmin", "admin", "manager"] },
    children: [
      { path: "/", redirect: "list" },
      {
        path: "/activity/list",
        component: () => import("@/views/Activity/List.vue"),
        name: "activities_list"
      }
    ]
  }
];

export default routes;
