const routes = [
  {
    path: '/store_fronts/panel/:id/preview',
    component: () => import('@/views/StoreFront/Panel/Preview/Index.vue'),
    name: 'store_front_preview',
    meta: { role: ['superadmin'] },
    children: [
      {
        path: '/store_fronts/panel/:id/preview/dashboard',
        component: () => import('@/views/StoreFront/Panel/Preview/Dashboard.vue'),
        name: 'store_front_preview_dashboard',
        meta: { role: ['superadmin'] },
      }
    ]
  }
];

export default routes;
