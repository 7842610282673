import Model from './Model';
import IOrderStatus from './interfaces/IOrderStatus';
import { Activity } from '.';
export default class OrderStatus extends Model implements IOrderStatus {
  static type = 'orderStatuses';

  name: string;
  code: string;
  activities: Activity[];
  cancelable: boolean;
  color: string;
}
