import Model from "./Model";
import { Address, PaymentMethod, Customer, OrderLine, Shipment } from ".";
import IOrder from "./interfaces/IOrder";
import {OrderStatus} from './';
import Coupon from "@/models/woocommerce/Coupon";

export const ORDER_TYPES: Array<{ label: string; value: string }> = [
  { label: 'Tutti', value: 'all' },
  { label: 'Woocommerce', value: 'woocommerce' },
  { label: 'B2B', value: 'b2b' },
  { label: 'Custom', value: 'custom' },
];

export default class Order extends Model implements IOrder {
  static type = "orders";

  static selectableColumns = [
    { id: "tracking_code", label: "Codice tracking", type: "text" },
    { id: "tracking_url", label: "Link Tracking", type: "text" },
    { id: "total", label: "Total", type: "text" },
    { id: "external_id", label: "Id Ecommerce", type: "text" },
    { id: "customer_first_name", label: "Nome cliente", type: "text" }
  ];

  externalId: string;
  totalPieces: string;
  total: number;
  shipment: Shipment;
  orderStatus: OrderStatus;
  printStatus: string;
  importStatus: string;
  shipmentStatus: string;
  approvedStatus: string;
  completedAt: string;
  status: string;
  customer: Customer;
  paymentMethod: PaymentMethod;
  billing: Address;
  shipping: Address;
  labelUrl: string;
  qrCodeUrl: string;
  coupons: Coupon[];
  orderLines: OrderLine[];
  storeFrontId: number;
  rawData: any;
  b2b: boolean;
  type!: string;

  get imported() {
    return this.importStatus === "imported";
  }

  get approved() {
    return this.approvedStatus === "approved";
  }

  get printed() {
    return this.printStatus === "printed";
  }

  get shipped() {
    return this.shipmentStatus === "shipped";
  }

  get statusClass() {
    switch (this.orderStatus.code) {
      case "completed":
        return `badge-filled-success`;
      case "processing":
        return `badge-filled-warning`;
      case "shipped":
        return `badge-filled-primary`;
      case "canceled":
        return `badge-filled-danger`;
    }
    return "";
  }
}
