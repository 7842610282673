import Model from "./Model";
import User from "./User";
import Source from "./Source";


export default class SourceUser extends Model {
  static type = "sourceUsers";

  source: Source;
  user: User;

}

export class SourceUserPayload {


  constructor(sourceUser: any){
    this.id = sourceUser?.id||null;
    this.sourceId = sourceUser?.source?.id||null;
    this.userId = sourceUser?.user?.id||null;

  }

  id: string;
  sourceId: string;
  userId: string;

}
