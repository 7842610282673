import Model from "../Model";
import Source from "../Source";
import { User } from "..";
import { FormItem } from "../ViewModels";

export class WoocommerceConfiguration {
  url: string;
  email: string;
  consumerKey: string;
  consumerSecret: string;
  zakekeClient: string;
  zakekeSecret: string;
  mailgunDomain: string;
  mailgunApiKey: string;
  labelBackground: string;
  labelTextColor: string;
}

export default class WoocommerceSource extends Source {
  static type = "woocommerceSources";
  type = "Woocommerce::Source";

  get activated() {
    return !!(this.consumerKey && this.consumerSecret);
  }

  get consumerKey() {
    return this.configuration?.consumerKey;
  }

  set consumerKey(value: string) {
    this.configuration.consumerKey = value;
  }

  get consumerSecret() {
    return this.configuration?.consumerSecret;
  }

  set consumerSecret(value: string) {
    this.configuration.consumerSecret = value;
  }

  get zakekeSecret() {
    return this.configuration?.zakekeSecret;
  }

  set zakekeSecret(value: string) {
    this.configuration.zakekeSecret = value;
  }

  get mailgunDomain() {
    return this.configuration?.mailgunDomain;
  }

  set mailgunDomain(value: string) {
    this.configuration.mailgunDomain = value;
  }

  get zakekeClient() {
    return this.configuration?.zakekeClient;
  }

  set zakekeClient(value: string) {
    this.configuration.zakekeClient = value;
  }

  get mailgunApiKey() {
    return this.configuration?.mailgunApiKey;
  }

  set mailgunApiKey(value: string) {
    this.configuration.mailgunApiKey = value;
  }

  set labelBackground(value: string) {
    this.configuration.labelBackground = value;
  }

  get labelBackground() {
    return this.configuration.labelBackground;
  }

  set labelTextColor(value: string) {
    this.configuration.labelTextColor = value;
  }

  get labelTextColor() {
    return this.configuration.labelTextColor;
  }

  get url() {
    return this.configuration?.url;
  }

  set url(value: string) {
    this.configuration.url = value;
  }

  set email(value: string) {
    this.configuration.email = value;
  }

  get email() {
    return this.configuration?.email;
  }

  get fields(): FormItem[] {
    return [
      { label: "Nome", type: "string", id: "name" },
      { label: "Url", type: "string", id: "url" },
      { label: "Email", type: "string", id: "email" },
      { label: "Consumer Key", type: "string", id: "consumerKey" },
      { label: "Consumer Secret", type: "string", id: "consumerSecret" }
    ];
  }

  get integrationFields(): FormItem[] {
    return [
      { label: "Zakeke Client", type: "string", id: "zakekeClient" },
      { label: "Zakeke Secret", type: "string", id: "zakekeSecret" },
      { label: "Mailgun Api Key", type: "string", id: "mailgunApiKey" },
      { label: "Mailgun Domain", type: "string", id: "mailgunDomain" },
    ];
  }

  get logisticsFields(): FormItem[] {
    return [
      { label: "Suffisso BDA", type: "string", id: "shipmentSuffix", prepend: "{id ordine}-" },
      { label: "Abilita fatturazione", type: "checkbox", id: "invoicingEnabled" },
      { label: "Fee ordine", type: "number", id: "orderFee",append: '€' },
      { label: "Necessita di approvazione", type: "checkbox", id: "needsApproval" },
    ];
  }

  get printsFields(): FormItem[] {
    return [
      { label: "Colore Etichetta ordine", type: "color", id: "labelBackground" },
      { label: "Colore Testo Etichetta ordine", type: "color", id: "labelTextColor" },
    ];
  }
}
