



































import { Component, Mixins } from "vue-property-decorator";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import { Getter, namespace } from "vuex-class";
import { RegisterHttp } from "@/utils/Decorators";
import { Source, User } from "@/models";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast, showToast } from "@/utils/Toast";
import { easync } from "@/utils/http";
import { currentUserStore, sourceStore } from "@/store/typed";

const tag = "get_sources";

@Component({})
export default class Select extends Mixins(SourcesMixin) {
  open = false;

  sources: Source[] = [];

  @RegisterHttp(tag) getSourcesRequest: RequestData;

  get loading() {
    return this.getSourcesRequest?.loading;
  }

  get source() {
    return sourceStore.currentSource;
  }

  onSourceSelected(source: Source) {
    this.open = false;
    sourceStore.dispatchSetCurrentSource(source);
    if(this.$route.name !== 'source_webhooks'){
      this.$router.push({name: 'source_webhooks'})
    }
    showToast(`Benvenuto nella dashboard di ${source.name}"`, {position: 'bottom-left', type:'info', duration: 1500},)
  }

  mounted() {
    this.loadSources();
  }

  async loadSources() {
    let data: any = {
      filter: {}
    };

    if (currentUserStore.currentUser.isCompanyRole) {
      data.filter.company = currentUserStore.currentUser.company.id;
    }

    const [res, errors] = await easync(this.getSources(data, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.sources = res;
    if (!sourceStore.currentSource) {
      sourceStore.dispatchSetCurrentSource(this.sources[0]);
    } else {
      sourceStore.dispatchSetCurrentSource(this.sources.find(o => o.id === sourceStore.currentSource?.id));
    }
  }
}
