import { Label } from '.';
import Model from './Model';
import { FormItem } from './ViewModels';

export class PrintTemplatePayload {

  printTemplateId: number = null;
  printableId: number = null;
  printableType: string = null;
}

export default class PrintablePrintTemplate extends Model {
  static type = 'printablePrintTemplates';

  printable: Label;


}
