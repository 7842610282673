const routes = [
  { path: '/products/show/:id', component: () => import(/* webpackChunkName: "source" */ '@/views/Product/Show.vue'), name: 'products_show'}
,
  {
    path: '/products', component: () => import(/* webpackChunkName: "source" */ '@/views/Product/Dashboard.vue'), name: 'products', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      { path: '/products/list', component: () => import(/* webpackChunkName: "source" */ '@/views/Product/List.vue'), name: 'products_list'},
      { path: '/products/import_dashboard', component: () => import(/* webpackChunkName: "source" */ '@/views/Product/ConfigurationDashboard.vue'), name: 'products_import_dashboard'},

    ]
  }
];

export default routes;
