import { Order, ShippingContract, Tracking } from ".";
import Model from "./Model";

export default class Shipment extends Model {
  static type = "shipments";

  static statuses: Array<{ name: string; id: string; style?: string }> = [
    { name: "Non tracciata", id: "untracked", style: "primary" },
    { name: "Spedita", id: "shipping", style: "warning" },
    { name: "Errore", id: "error", style: "danger" },
    { name: "Completata", id: "shipped", style: "success" },
    { name: "Distrutta", id: "smashed", style: "danger" },
    { name: "Resa", id: "returned", style: "success" },
    { name: "Rimborsata", id: "refunded", style: "success" },
  ];

  static statusLabel(id: string) {
    return Shipment.statuses.find(o => o.id === id)?.name || '-';
  }

  order: Order;
  status: string;
  trackingCode: string;
  trackingUrl: string;
  trackings: Tracking[];
  reason: string;
  shippingContract: ShippingContract;
  when: Date;

  get formattedStatus() {
    return Shipment.statuses.find(o => o.id === this.status)?.name;
  }

  get statusStyle() {
    return Shipment.statuses.find(o => o.id === this.status)?.style;
  }

  get carrierLogo() {

    return this.carrier ? require(`../assets/images/${this.carrier?.code}.png`) : '';
  }

  get carrierName() {
    return this.carrier?.name;
  }

  get carrier() {
    return ShippingContract.carriers.find(
      o => this.shippingContract.type === o.id
    );
  }
}
