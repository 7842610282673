const routes = [
  {
    path: '/cut_files', component: () => import('@/views/CutFile/Dashboard.vue'), name: 'cut_files', meta: { role: ['superadmin'] },
    children: [
      { path: '/', redirect: 'list' },
      {
        path: '/cut_files/list', component: () => import('@/views/CutFile/List.vue'), name: 'cut_files_list'
      }
    ]
  }
];

export default routes;
